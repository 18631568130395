<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel" v-html="title"></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
          ></button>
        </div>
        <div class="modal-body" v-html="text"></div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            v-on:click="cancelEvent()"
          >
            {{ cancelButton }}
          </button>
          <button
            v-if="showOk"
            type="button"
            class="btn btn-danger"
            v-on:click="successEvent()"
          >
            {{ okButton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const bootstrap = require("bootstrap");

export default {
  name: "ConfirmModal",
  props: {
    title: String,
    text: String,
    showOk: {
      default() {
        return true;
      },
      type: Boolean,
    },
    cancelButton: {
      default() {
        return "Cancel";
      },
      type: String,
    },
    okButton: {
      default() {
        return "Ok";
      },
      type: String,
    },
  },
  data() {
    return {
      myModal: undefined,
    };
  },
  emits: ["success", "cancel"],
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
    successEvent() {
      this.myModal.hide();
      this.$emit("success");
    },
    cancelEvent() {
      this.myModal.hide();
      this.$emit("cancel");
    },
  },
};
</script>
